<!-- Footer-->


<footer class="footer-social">
    <div class="container">
      <div class="row">
        <div class="col-md-4 footer-info">
          <a href="https://codeploy.it/"><img src="assets/img/logocodeploy.png" width="180" alt=""></a>
          <p>Designed by <a href="https://codeploy.it/">Codeploy</a> </p>
        </div>

        <div class="col-md-4 footer-info">
          <li> <span class="flaticon-location footer-icon align-middle"></span> <a href="https://www.google.it/maps/place/Largo+Vittorio+Emanuele+II,+87,+10128+Torino+TO/@45.0649569,7.6688583,17z/data=!3m1!4b1!4m5!3m4!1s0x47886d1470e1eabb:0x69a818228ac75cb5!8m2!3d45.0649531!4d7.671047" target="blank" >Largo Vittorio Emanuele II 87, Torino</a></li>
          <li> <span class="flaticon-call footer-icon align-middle"></span> <a href="tel:+39 011 5628 039">011 5628 039</a> </li>

        </div>

        <div class="col-md-4 footer-info">
          <li> <span class="flaticon-email footer-icon align-middle"></span> <a href="mailto:info@immobiliarequattrocorsi.it">info@immobiliarequattrocorsi.it</a></li>
          <li> <span class="flaticon-facebook footer-icon align-middle"></span> Partita IVA: 01727260018</li>

        </div>
      </div>
     
      </div>


  </footer>

