<!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.027507046216!2d7.668858315544115!3d45.064953079098196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d1470e1eabb%3A0x69a818228ac75cb5!2sLargo%20Vittorio%20Emanuele%20II%2C%2087%2C%2010128%20Torino%20TO!5e0!3m2!1sit!2sit!4v1588685852986!5m2!1sit!2sit" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->

<!-- Cover section-->


<div class="container-fluid about-cover">
  <div class="row">
    <div class="col-md-12">

      <div class="about-text">
        <h4>Contattaci</h4>
      </div>
    </div>

  </div>
</div>


<div class="container contact-section section_padding_100_100">

  <div class="row">
    <div class="col-md-6 contact-box">
      <form class="form-info" #contactForm="ngForm">
        <div class="form-group">
          <input type="nome" name="name" class="form-control" id="nome" [(ngModel)]="name" placeholder="Nome" required>
        </div>

        <div class="form-group">
          <input type="cognome" name="surname" class="form-control" id="cognome" [(ngModel)]="surname"
            placeholder="Cognome" required>
        </div>

        <div class="form-group">
          <input type="number" name="phone" class="form-control" id="telefono" [(ngModel)]="phone"
            placeholder="Telefono">
        </div>

        <div class="form-group">
          <input type="email" name="email" class="form-control" id="E-mail" [(ngModel)]="email" placeholder="E-mail"
            required>
        </div>

        <div class="form-group">
          <textarea class="form-control" name="message" id="exampleFormControlTextarea1" [(ngModel)]="message" rows="3"
            required>Scrivi qui il tuo messaggio</textarea>
        </div>

        <div class="container-check">
          <label class="check-privacy">Ho letto e acconsento <a
              href="https://s3-eu-west-1.amazonaws.com/com.codeploy.immobiliare4corsi.annunci.prod/Informativa+privacy+di+Immobiliare4Corsi.pdf"
              target="_blank">l'informativa sulla privacy</a>
            <input type="checkbox" [(ngModel)]="privacyChecked" name="privacy">
            <span class="checkmark"></span>
          </label>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-form" name="buttonSend"
            [disabled]="!privacyChecked || contactForm.form.invalid" (click)="sendMail(template)">Invia
            messaggio</button>
        </div>
      </form>
    </div>



    <div class="col-md-6 text-center">
      <div class="map-title">
        <h4>Dove ci troviamo</h4>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.027507046216!2d7.668858315544115!3d45.064953079098196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d1470e1eabb%3A0x69a818228ac75cb5!2sLargo%20Vittorio%20Emanuele%20II%2C%2087%2C%2010128%20Torino%20TO!5e0!3m2!1sit!2sit!4v1588685852986!5m2!1sit!2sit"
        width="400" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"
        class="map"></iframe>
    </div>


  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title"> <img src="assets/icons/contatti.png" alt="" width="45"> {{feedBack}}</h4>
  </div>
</ng-template>