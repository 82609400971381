// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// import { HttpHeaders } from '@angular/common/http';

// export const environment = {
//   production: false,

//   // DEV: https://6e2pwcza4c.execute-api.eu-west-1.amazonaws.com/dev/
//   // PROD: https://api-manager.codeploy.cloud/
//   endpoint: 'https://6e2pwcza4c.execute-api.eu-west-1.amazonaws.com/dev/',
//   endpointManager: 'https://api-web-dev.codeploy.cloud/api/web/',
//   apiCmsManager: 'public/cms/',
//   apiCoreManager: 'public/core/',

//   component: 'i4c',

//   seoDefaultTitle: 'Quattrocorsi',

//   header: new HttpHeaders()
//     .set('Content-Type', 'application/json')
//     .set('Authorization', 'Basic aW1tb2JpbGlhcmU6aW1tb2JpbGlhcmU0Y29yc2k=')
//     .set('x-api-key', 'VOXDCZOzbs7KWDmSsQf2N3FhjIFnuO319zdW4oHa')
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: true,

  // DEV: https://6e2pwcza4c.execute-api.eu-west-1.amazonaws.com/dev/
  // PROD: https://api-manager.codeploy.cloud/
  endpointManager: 'https://api-web.codeploy.cloud/api/web/',
  apiCmsManager: 'public/cms/',
  apiCoreManager: 'public/core/',

  component: 'i4c',

  seoDefaultTitle: 'Quattrocorsi',

  header: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Basic ' + btoa(unescape(encodeURIComponent('immobiliare4corsi2020' + ':' + 'Imm0b1l14r34c0rs12020')))).
    set('x-api-key', 'BibNv7KbNV5awDMv2WflJa6kg5bTaKPP5J4vBE9O')
};
