// tslint:disable:curly variable-name

import { Injectable, Inject } from '@angular/core';
import { RouterEvent, Router, Event,
         NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from './seo.service';
import { SeoData } from '../../model/seo';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' }) export class SeoInitializerService {

  private static BUCKET_BASE_URL = 'https://s3-eu-west-1.amazonaws.com/com.codeploy.seoconf/';

  constructor(@Inject(DOCUMENT) private _document: Document,
              private router: Router,
              private seoService: SeoService,
              private http: HttpClient) { }

  public initialize(): Promise<boolean> {
    const url = SeoInitializerService.BUCKET_BASE_URL +
      `${this.extractHostname()}-seo.json`;

    return this.http.get<SeoData>(url).toPromise().then(seoData => {
      this.seoService.seoData = seoData;
      this.router.events.pipe(
        filter((evt: Event) =>
          evt instanceof NavigationStart || evt instanceof NavigationEnd)
      )
      .subscribe((evt: RouterEvent) => {
        if (evt instanceof NavigationEnd)
             this.seoService.insertSeoData(evt.url);
        else this.seoService.removeSeoData();
      });
    }).then(() => Promise.resolve(true))
    .catch(error => {
      console.error('Error while loading seo configuration', error);
      return Promise.resolve(false); });
  }

  private extractHostname(): string {
    const regex = /(www\.)?(.*)/;
    const group = regex.exec(this._document.location.hostname);
    return group !== null ? group[2] : null;
  }
}
