// tslint:disable: typedef-whitespace

class Meta {
    name: string = null; content: string = null;
}
class SeoPage {
    title  : string = null;
    meta   : Meta[] = [] ;
    jsonld : object[] = [];
}
class SeoData {
    [key: string]: SeoPage ;
}

export { SeoData, SeoPage, Meta };
