// tslint:disable: variable-name curly one-line align

import { Injectable, Inject } from '@angular/core';
import { SeoData, SeoPage } from '../../model/seo';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' }) export class SeoService {

  static emptySeo: SeoPage = Object.assign(new SeoPage(), {
      title: environment.seoDefaultTitle
  });
  private _seoData: SeoData = new SeoData() ;
  private actualSeoPage: SeoPage = SeoService.emptySeo ;

  constructor(@Inject(DOCUMENT) private _document: Document,
              private titleService: Title,
              private metaService: Meta) { }

  public get seoData() { return this._seoData; }

  public set seoData(data: SeoData) { this._seoData = data; }

  public insertSeoData(url: string): void {
    const mostRelevant: SeoPage = this.findMostRelavantSeoPage(url);

    if (mostRelevant !== null) {
      this.actualSeoPage = mostRelevant;
      this.titleService.setTitle(this.actualSeoPage.title);
      this.metaService .addTags (this.actualSeoPage.meta
          .map(meta => { return {
            name: meta.name, content: meta.content
        } as MetaDefinition; }));

      this.actualSeoPage.jsonld.map((jsonld, idx) =>
          this.createJsonldScript(jsonld, idx))
        .forEach(script => this._document.head.appendChild(script));
    }
    else { this.actualSeoPage = SeoService.emptySeo;
      console.warn(`No SEO data found for route ${url}`); }
  }

  private findMostRelavantSeoPage(url: string): SeoPage {

    const mostRelevant: string = Object.keys(this.seoData)
      .filter(route => route.startsWith(url))
      .reduce((prev, curr) =>
        prev.length > curr.length ? prev : curr, '');

    return mostRelevant.length > 0 ? this.seoData[mostRelevant] : null;
  }

  private createJsonldScript(schema: object, idx: number): HTMLScriptElement {

    const script: HTMLScriptElement = Object.assign(
      this._document.createElement('script'), {
        type: 'application/ld+json',
        text: JSON.stringify(schema)
    });
    script.setAttribute('class', `jsonld-${idx}`);
    return script;
  }

  public removeSeoData(): void {
    this.titleService.setTitle(SeoService.emptySeo.title);

    this.actualSeoPage.meta.map(meta => `name='${meta.name}'`)
      .map(meta => this.metaService.getTag(meta))
      .forEach(meta => this.metaService.removeTagElement(meta));

    [...this.actualSeoPage.jsonld.keys()]
      .map(idx => this._document.head
          .getElementsByClassName(`jsonld-${idx}`)[0])
      .filter(script => script !== undefined)
      .forEach(script => this._document.head.removeChild(script));
  }
}
