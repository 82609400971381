import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AnnuncievidenzaComponent } from './home/annuncievidenza/annuncievidenza.component';
import { AnnunciComponent } from './annunci/annunci.component';
import { ChisiamoComponent } from './chisiamo/chisiamo.component';
import { ContattiComponent } from './contatti/contatti.component';
import { FooterComponent } from './footer/footer.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SeoModule } from './seo/seo.module';


@NgModule({
   declarations: [
      AppComponent,
      NavbarComponent,
      HomeComponent,
      AnnuncievidenzaComponent,
      AnnunciComponent,
      ChisiamoComponent,
      ContattiComponent,
      FooterComponent
   ],
   imports: [
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      HttpClientModule,
      CheckboxModule,
      ModalModule.forRoot(),
      CarouselModule.forRoot(),
      NgbModule,
      FormsModule,
      NgImageSliderModule,
      NgxSpinnerModule,
      BrowserAnimationsModule,
      SeoModule
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
