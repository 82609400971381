/** Get all content by project component from CMS */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ContentResponse } from '../model/content';

@Injectable({
        providedIn: 'root'
})
export class AnnunciService {

        uri: string = environment.endpointManager + environment.apiCmsManager;
        constructor(private http: HttpClient) { }

        getContentsByProject(): Promise<ContentResponse> {

                const url: string = this.uri + 'v1/project/contents';

                return this.http.get<ContentResponse>(url, {
                        headers: environment.header,
                        params: {
                                component: environment.component,
                        }
                }).toPromise();
        }
}