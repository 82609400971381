<!-- Cover  section-->

<div *ngIf="!homePage" class="container">
  <div class="row">
    <div class="col-md-12 text-center">

      <div class="about-text">
        <h4>Annunci</h4>
      </div>
    </div>

  </div>
</div>

<!-- Annunci section-->

<div class="container about-section section_padding_100_100" style="position: relative;">
  <ngx-spinner bdColor="rgba(0,0,0,0.0)" size="large" color="#12592d" type="ball-scale-multiple" [fullScreen]="false">
  </ngx-spinner>
  <div *ngFor="let annuncio of contents" class="row">
    <div class="col-md-12 card">
      <div class="row">
        <div class="col-lg-5 card-img text-center">
          <!-- <img src={{annuncio.data.img1.url}} alt="" > -->

          <!-- <carousel [isAnimated]="true" *ngIf="annuncio.data">
            <slide *ngIf="annuncio.data.img1">
              <img src="{{annuncio.data.img1.url}}" alt="first slide" style="display: block; width: 100%; height: 50%">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[0]">
              <img src="{{annuncio.data.images[0].url}}" alt="second slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[1]">
              <img src="{{annuncio.data.images[1].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[2]">
              <img src="{{annuncio.data.images[2].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[3]">
              <img src="{{annuncio.data.images[3].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[4]">
              <img src="{{annuncio.data.images[4].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[5]">
              <img src="{{annuncio.data.images[5].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[6]">
              <img src="{{annuncio.data.images[6].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[7]">
              <img src="{{annuncio.data.images[7].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[8]">
              <img src="{{annuncio.data.images[8].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
            <slide *ngIf="annuncio.data.images && annuncio.data.images[9]">
              <img src="{{annuncio.data.images[9].url}}" alt="third slide" style="display: block; width: 100%;">
            </slide>
          </carousel> -->
          <ng-image-slider #nav [images]="imageObject.get(annuncio.id)" [infinite]="true" [animationSpeed]="0.7"
            [imageSize]="{width: '100%', height: 250}" [imagePopup]="true" slideImage="1"></ng-image-slider>

        </div>
        <div class="col-lg-7 card-title">
          <h3>{{annuncio.data.title}}</h3>

          <div class="detail-section">
            <div class="detail tag">
              <button class="no-click" *ngIf="annuncio.data.type == 1"> <img src="assets/icons/tag.png" alt=""
                  width="20">
                BOX</button>
              <button class="no-click" *ngIf="annuncio.data.type == 2"> <img src="assets/icons/tag.png" alt=""
                  width="20">
                UFFICIO</button>
              <button class="no-click" *ngIf="annuncio.data.type == 3"> <img src="assets/icons/tag.png" alt=""
                  width="20">
                MAGAZZINO</button>
            </div>
            <div *ngIf="annuncio.data.square" class="detail info"> <img src="assets/icons/plans.png" alt="" width="35">
              {{annuncio.data.square}} m2</div>
            <div *ngIf="annuncio.data.floor" class="detail info"> <img src="assets/icons/stairs.png" alt="" width="35">
              {{annuncio.data.floor}}° piano</div>
          </div>

          <p [innerHTML]=annuncio.data.desc></p>

          <div *ngFor="let tag of annuncio.data.tag" class="tag-minor"> <button class="no-click"> <img
                src="assets/icons/tag.png" alt="" width="17"> {{tag.toUpperCase()}}</button>
          </div>

          <div class="text-right price">
            <p> da <span style="color: #12592D; font-size: 30px; font-weight: 500;">{{annuncio.data.rentalPrice}}€
              </span>/mese</p>
            <button class="confirm-button" (click)="openModal(template, annuncio)">Contatta</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>

    <h4 class="modal-title"> <img src="assets/icons/contatti.png" alt="" width="45"> Contattaci</h4>
  </div>
  <div class="modal-body">

    <form class="form-info" #contactForm="ngForm">
      <div class="form-group">
        <input type="nome" name="name" class="form-control" id="nome" [(ngModel)]="name" placeholder="Nome" required>
      </div>

      <div class="form-group">
        <input type="cognome" name="surname" class="form-control" id="cognome" [(ngModel)]="surname"
          placeholder="Cognome" required>
      </div>

      <div class="form-group">
        <input type="number" name="phone" class="form-control" id="telefono" [(ngModel)]="phone" placeholder="Telefono">
      </div>

      <div class="form-group">
        <input type="email" name="email" class="form-control" id="E-mail" [(ngModel)]="email" placeholder="E-mail"
          required>
      </div>

      <div class="form-group">
        <textarea class="form-control" name="message" id="exampleFormControlTextarea1" [(ngModel)]="message" rows="3"
          required>Scrivi qui il tuo messaggio</textarea>
      </div>

      <!-- <div class="container-check"> 
        <label class="check-privacy">Ho letto e acconsento <a href="https://s3-eu-west-1.amazonaws.com/com.codeploy.immobiliare4corsi.annunci.dev/Informativa+privacy+di+Immobiliare4Corsi.pdf" target="_blank">l'informativa sulla privacy</a>
            <input type="checkbox" [(ngModel)]="privacyChecked" name="privacy"> 
            <span class="checkmark"></span>
          </label>
        </div> -->
      <div class="container-check row">
        <input type="checkbox" [(ngModel)]="privacyChecked" name="privacy">
        <label class="check-privacy">Ho letto e acconsento <a
            href="https://s3-eu-west-1.amazonaws.com/com.codeploy.immobiliare4corsi.annunci.prod/Informativa+privacy+di+Immobiliare4Corsi.pdf"
            target="_blank">l'informativa sulla privacy</a>
          <!-- <span class="checkmark"></span> -->
        </label>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-form" name="buttonSend"
          [disabled]="!privacyChecked  || contactForm.form.invalid" (click)="sendMail()">Invia messaggio</button>
      </div>
    </form>

  </div>
</ng-template>