import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MailService } from '../service/mail.service';
import { FormContact } from '../model/mail';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.css']
})
export class ContattiComponent implements OnInit {

  modalRef: BsModalRef;

  constructor(private mailService: MailService,
    private modalService: BsModalService) { }

  private formContact: FormContact;
  public contactForm: FormGroup;
  public name = '';
  public surname = '';
  public phone = '';
  public email = '';
  public message = '';
  public privacyChecked = false;

  public successSend: String = "Email inviata correttamente!";
  public failedSend: String = "Errore durante l'invio della mail";

  feedBack: String = "";

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>, feedback: String) {
    this.modalRef = this.modalService.show(template);
    this.feedBack = feedback;
  }

  sendMail(template: TemplateRef<any>) {
    // tslint:disable-next-line: max-line-length
    this.formContact = new FormContact(this.name + ' ' + this.surname, this.message, this.email, this.phone, null, null);
    this.mailService.sendContactMail(this.formContact).then(
      (res) => {
        if (res.status === 'success') {
          this.openModal(template, this.successSend);
        } else {
          console.error(res.msg);
          this.openModal(template, this.failedSend);
        }
        this.name = '';
        this.email = '';
        this.phone = '';
        this.message = '';
        this.surname = '';
        this.privacyChecked = false;
      },
      (err) => { console.error('errore', err); }
    );
  }
}
