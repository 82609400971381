
<app-navbar></app-navbar>


<!-- Cover image section-->

<div class="col-12 cover-image">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="cover-text ">
            <h1>Cerchi uno spazio adatto alle tue esigenze?</h1>
            <h4>Immobiliare Quattro Corsi dispone di svariati servizi: box auto, magazzini e uffici in affitto. </h4>
            <a href="#servizi"><button class="confirm-button"> Scopri di più </button></a>
          </div>
        </div>

       

        </div>
      </div>

  </div>

  <!-- Servizi section-->

  <div class="container-fluid values-section section_padding_100_50" id="servizi">
    <div class="row text-center">
      <div class="col-12">
        <div class="section-heading ">
        <h3>3 motivi per scegliere Immobiliare Quattro Corsi</h3>

          </div>
        </div>
      </div>

    <div class="container section_padding_50 text-center">
      <div class="row">

        <div class="col-lg-4 col-md-6 mb-lg-0">
          <div class="media custom-media">
            <div class="media-body">
              <div class="icon"><img src="assets/icons/parking.png" alt="" width="70"></div>
              <h5 class="">Fuori dalla ZTL</h5>
              <p>L’immobile si trova nel centro di Torino, fuori dalla ZTL, in una zona con ampi parcheggi che agevolano le manovre.</p>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 mb-lg-0">
          <div class="media custom-media">
            <div class="media-body">
              <div class="icon"><img src="assets/icons/truck.png" alt="" width="80"></div>
              <h5 class="">Adatto alla logistica</h5>
              <p>Perfetto per i magazzini grazie alla presenza di montacarichi e alla possibilità di carico e scarico merci. Inoltre i camion hanno la possibilità di posteggiare nelle zone limitrofe ai magazzini.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mb-lg-0">
            <div class="media custom-media">
              <div class="media-body">
                <div class="icon"><img src="assets/icons/map.png" alt="" width="60"></div>
                <h5 class="">Strategico</h5>
                <p>Ottimo per gli uffici, situato vicino alle principali stazioni ferroviarie, è provvisto di spazi ampi, silenziosi e luminosi. Comodo anche per la vicinanza di bar, ristoranti e negozi.</p>
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Servizi section-->

  <div class="container-fluid values-section section_padding_100_50" id="servizi">
    <div class="row text-center">
      <div class="col-12">
        <div class="section-heading ">
        <h3>I nostri servizi</h3>

          </div>
        </div>
      </div>

    <div class="container section_padding_50 text-center">
      <div class="row">

        <div class="col-lg-4 col-md-6 mb-lg-0">
          <div class="media custom-media">
            <div class="media-body">
              <div class="icon"><img src="assets/icons/box.png" alt="" width="60"></div>
              <h5 class="">Box auto</h5>
              <p>Box auto seminterrati con doppio ingresso sia carraio sia pedonale, dotati di ascensori e montacarichi.</p>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 mb-lg-0">
          <div class="media custom-media">
            <div class="media-body">
              <div class="icon"><img src="assets/icons/ufficio.png" alt="" width="60"></div>
              <h5 class="">Uffici</h5>
              <p>Uffici spaziosi, luminosi e confortevoli. I nostri spazi sono adatti ad ogni tipo di business e attività.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mb-lg-0">
            <div class="media custom-media">
              <div class="media-body">
                <div class="icon"><img src="assets/icons/garage.png" alt="" width="60"></div>
                <h5 class="">Magazzini</h5>
                <p>Magazzini ampi e riscaldati conformi ad ogni tipo di esigenza, adatti a carico e scarico merci con eventuale box auto.</p>
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>


  <!-- Annunci in evidenza section-->

  <div class="row text-center">
    <div class="col-12">
      <div class="section-heading ">
      <h3>Annunci in evidenza</h3>

        </div>
      </div>
    </div>

  <app-annunci homepage='true'></app-annunci>

  <!-- <app-annuncievidenza></app-annuncievidenza> -->
 

<div class="container text-center">
  <div class="row">
    <div class="col-md-12">
      <button class="confirm-button"> <a href="/annunci">Scopri tutti gli annunci</a> </button>
    </div>
  </div>
</div>
  

      <!-- Contact section-->

      <div class="section_padding_100_100">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center contact-section">
              <h3>Vorresti avere più informazioni?</h3>
            </div>

            <div class="col-md-6 text-center contact-section">
              <img src="assets/icons/email.png" alt="mail-icon" width="45">
              <p>Contattaci via e-mail all'indirizzo <br> <a href="mailto:info@immobiliarequattrocorsi.it">info@immobiliarequattrocorsi.it</a> </p>
            </div>

            <div class="col-md-6 text-center contact-section">
              <img src="assets/icons/phone.png" alt="mail-icon" width="38">
              <p>Contattaci al numero di telefono <br> <a href="tel:+39 011 5628 039">+39 011 5628 039</a> </p>
            </div>

            <!-- <div class="col-md-6 text-center contact-section-nobg">
              <i class="flaticon-facebook"></i>
              <p>Scrivimi su Facebook sulla pagina <br> <a href="https://www.facebook.com/gracetable.torino/" target="blank">GraceTable</a></p>
            </div> -->

          </div>

        </div>

      </div>