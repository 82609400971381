import { Content } from './content';


class AnnuncioImage {
    filename: string;
    key: string;
    url: string;
}
class AnnuncioContent extends Content {
    data = new class {
        title: string = null;
        desc: string = null;
        rentalPrice: number = null;
        square: number = null;
        floor: number = null;
        creationDate: Date = null;
        lastUpdate: Date = null;
        status: number = null;
        type: number = null;
        tag: string[] = null;
        img1: AnnuncioImage = null;
        img2: AnnuncioImage = null;
        images: AnnuncioImage[] = null;
    }();
}

class SliderImage {
    image: string;
    thumbImage: string;
    title: string;
    alt: string;
}

export { AnnuncioContent, AnnuncioImage, SliderImage };
