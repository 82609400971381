import { Component, TemplateRef, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnnunciService } from '../service/annunci.service';
import { AnnuncioContent, SliderImage } from '../model/annuncio-content';
import { FormGroup } from '@angular/forms';
import { FormContact } from '../model/mail';
import { MailService } from '../service/mail.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-annunci',
  templateUrl: './annunci.component.html',
  styleUrls: ['./annunci.component.css']
})


export class AnnunciComponent implements OnInit {

  @Input('homepage') homePage: boolean;

  modalRef: BsModalRef;


  public contents: AnnuncioContent[] = [];
  public allContents: AnnuncioContent[];
  public contactAnnounce: AnnuncioContent;

  public contactForm: FormGroup;
  public name = '';
  public surname = '';
  public phone = '';
  public email = '';
  public message = '';
  public privacyChecked = false;

  private formContact: FormContact;

  imageObject1: Array<SliderImage> = [{
    image: 'https://s3-eu-west-1.amazonaws.com/com.codeploy.immobiliare4corsi.annunci.dev/1588841579136-Ny5qcGc%3D',
    thumbImage: 'https://s3-eu-west-1.amazonaws.com/com.codeploy.immobiliare4corsi.annunci.dev/1588841579136-Ny5qcGc%3D',
    alt: 'alt of image',
    title: 'title of image'
  }];

  imageObject: Map<string, Array<SliderImage>> = new Map();

  constructor(private service: AnnunciService,
    private mailService: MailService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.getContentList();
  }

  getImageObject() {
    for (let i = 0; i < this.contents.length; i++) {
      let sliderImages: Array<SliderImage> = [];
      let img: SliderImage = new SliderImage();
      img.image = this.contents[i].data.img1.url;
      img.thumbImage = this.contents[i].data.img1.url;
      sliderImages.push(img);
      if (this.contents[i].data.images != null) {
        let length = this.contents[i].data.images.length;
        this.contents[i].data.images.forEach(immagine => {
          img = new SliderImage();
          img.image = immagine.url;
          img.thumbImage = immagine.url;
          sliderImages.push(img);
        });
      }
      this.imageObject.set(this.contents[i].id, sliderImages);
    }
  }

  openModal(template: TemplateRef<any>, announce: AnnuncioContent) {
    this.modalRef = this.modalService.show(template);
    this.contactAnnounce = announce;
  }

  getContentList() {
    this.service.getContentsByProject().then(
      (res) => {
        if (res.status === 'success') {
          this.allContents = res.data;
          this.deleteInactiveAnnoucement(this.allContents);
          if (this.homePage) {
            this.contents.splice(2);
          }
          this.getImageObject();
          this.spinner.hide();
        } else { console.error(res.msg); }
      },
      (err) => { console.error('errore', err); }
    );
  }

  deleteInactiveAnnoucement(announcmentsList: AnnuncioContent[]) {
    announcmentsList.forEach(annuncio => {
      if (annuncio.data.status == 1) {
        this.contents.push(annuncio);
      }
    });
  }

  sendMail(template: TemplateRef<any>) {
    // tslint:disable-next-line: max-line-length
    this.formContact = new FormContact(this.name + ' ' + this.surname, this.message, this.email, this.phone, this.contactAnnounce.data.title, this.contactAnnounce.id);
    this.mailService.sendContactMail(this.formContact).then(
      (res) => {
        if (res.status !== 'success') {
          console.error(res.msg);
        }
        this.modalService.hide(1);
      },
      (err) => { console.error('errore', err); }
    );
  }

}
