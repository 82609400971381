export class FormContact {
        mail: string;
        message: string;
        name: string;
        phone: string;
        ann_title: string;
        ann_id: string;

        constructor(name: string, message: string, mail: string, phone: string, annTitle: string, annId: string) {
                this.mail = mail;
                this.message = message;
                this.name = name;
                this.phone = phone;
                this.ann_id = annId;
                this.ann_title = annTitle;
        }
}