<!-- ***** Header Area Start ***** -->
<header class="header_area animated">
    <div class="container-fluid">
        <div class="row align-items-center">

            <!-- Menu Area Start -->
            <div class="col-12 col-lg-12">
                <div class="menu_area">
                    <nav class="navbar navbar-expand-lg navbar-light">

                        <!-- Logo -->
                        <a class="navbar-brand" routerLink="/"> <img src="assets/img/logo.png" alt=""> </a>
                        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                            data-target="#quattrocorsi-navbar" aria-controls="quattrocorsi-navbar" aria-expanded="false"
                            aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
                            <span class="navbar-toggler-icon"></span></button>

                        <!-- Menu Area -->
                        <div class="collapse navbar-collapse" id="quattrocorsi-navbar" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav ml-auto" id="nav">
                                <li class="nav-item"><a class="nav-link" routerLink="/chisiamo" (click)="isCollapsed = !isCollapsed">Chi siamo</a></li>
                                <li class="nav-item"><a class="nav-link" routerLink="/annunci" (click)="isCollapsed = !isCollapsed">Annunci</a></li>
                                <li class="nav-item"><a class="nav-link" routerLink="/contatti" (click)="isCollapsed = !isCollapsed">Contatti</a></li>
                            </ul>
                        </div>

                    </nav>
                </div>
            </div>

        </div>
    </div>
</header>

<!-- ***** Header Area End ***** -->