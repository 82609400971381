
class Content {
        // mandatory params
        type: string = null;
        id: string = null;
        refId: string = null;
        userId: string = null;
        // generic content data
        data: any = null;
        links: ContentLink[] = [];
}
class ContentLink {
        type: string = null;
        fromId: string = null;
        fromRefId: string = null;
        toId: string = null;
        toRefId: string = null;
}

class ContentResponse {
        status: string;
        msg: string;
        data: Content[];
}

export { Content, ContentLink, ContentResponse };
