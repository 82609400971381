import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChisiamoComponent } from './chisiamo/chisiamo.component';
import { AnnunciComponent } from './annunci/annunci.component';
import { ContattiComponent } from './contatti/contatti.component';

const routes: Routes = [
  { path: '', component: HomeComponent, },
  { path: 'chisiamo', component: ChisiamoComponent, },
  { path: 'annunci', component: AnnunciComponent, },
  { path: 'contatti', component: ContattiComponent, }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
