<!-- Cover section-->


<div class="container">
  <div class="row">
    <div class="col-md-12 text-center">

      <div class="about-text">
        <h4>Chi siamo</h4>
      </div>
    </div>

  </div>
</div>




<!-- About section-->

<div class="container about-section section_padding_100_100">


  <div class="row about-row">
    <div class="col-lg-6 text-center about-col">
      <div class="about-image">
        <img src="assets/img/bn3.jpg" alt="" class="img-fluid" width="450" style="padding-right: 15px;">

      </div>
    </div>


    <div class="col-lg-6 about-col">
      <div class="about-description">
        <h4>L'IMMOBILIARE DEI 4 INCROCI</h4>
        <p>L’immobiliare Quattro Corsi srl nasce nel 1960 e prende il nome dall’incrocio simbolico dei quattro corsi che caratterizzano la posizione dello stabile. La società è a gestione familiare ed è attenta alla cura e al benessere dei suoi inquilini. </p>
        <p> L’immobile si trova in una zona centrale della città di Torino, ben collegata con i mezzi pubblici e strategica per i movimenti da e per Torino. Lo stabile si trova a pochi minuti a piedi dalla stazione ferroviaria di Porta Nuova e appena fuori dalla ztl, grande comodità per chi ha bisogno di spostarsi, ed avere un appoggio durante le ore della zona a traffico limitato. </p>
      </div>

    </div>

  </div>



  <div class="row about-row">

    <div class="col-lg-6 about-col">
      <div class="about-description">
        <h4>SERVIZI ALLA PORTATA DI TUTTI</h4>
        <p>La proprietà offre un servizio di portineria attivo per l’intero arco della giornata a cui è possibile rivolgersi per ogni evenienza (dal lunedì al venerdì dalle 8-20 e il sabato 8-11). Nel cortile sono disponibili parcheggi per disabili gratuiti per la sosta breve presso gli uffici dello stabile.</p>
        <p>L’ingresso pedonale è dotato di montascale e gli ascensori sono accessibili alle persone con disabilità. 
          La società risponde da sempre alle esigenze dei suoi conduttori, per tale ragione è stata richiesta e di seguito concessa la possibilità di una zona carico scarico di fronte all’ingresso di Via Magenta.</p>
      </div>
    </div>


    <div class="col-lg-3 col-6 text-center about-col">
      <div class="about-image">
        <img src="assets/img/bn1.jpg" alt="" class="img-fluid" width="400">
      </div>
    </div>

    <div class="col-lg-3 col-6 text-center about-col">
      <div class="about-image">
        <img src="assets/img/about3.jpg" alt="" class="img-fluid" width="400">
      </div>
    </div>

  </div>


  <div class="row about-row">
    <div class="col-lg-6 text-center about-col">
      <div class="about-image">
        <img src="assets/img/about4.jpg" alt="" class="img-fluid" width="400">

      </div>
    </div>


    <div class="col-lg-6 about-col">
      <div class="about-description">
        <h4>BENESSERE E RISPARMIO</h4>

        <p> Inoltre, sempre per favorire maggiori benefici ai suoi inquilini nel 2018 si è modificato l’impianto di riscaldamento passando a un sistema di teleriscaldamento permettendo così un notevole risparmio di consumi e di costi di gestione. 
      <br> La società si impegna quotidianamente a seguire le necessità ed i bisogni degli inquilini nel rispetto della privacy e delle norme di sicurezza. </p>
      </div>

    </div>

  </div>


</div>