import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeoInitializerService } from './services/initializer/seo-initializer.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [{
      provide: APP_INITIALIZER,
      useFactory: (seo: SeoInitializerService) => () => seo.initialize(),
      multi: true,
      deps: [SeoInitializerService]
  }]
})
export class SeoModule { }
