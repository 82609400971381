<!-- Annunci in evidenza section-->

<div class="container about-section section_padding_50_100">

    <div class="row section-heading text-center">
      <div class="col-12">
        <div class="section-heading ">
        <h3>Annunci in evidenza</h3>
          </div>
        </div>
      </div>

      <div class="row">
          <div class="col-md-12 card">
              <div class="row">
                  <div class="col-md-5 card-img text-center">
                      <img src="assets/img/garage.jpg" alt="" width="380">
                  </div>
                  <div class="col-md-7 card-title">
                      <h3>Box auto interrato</h3>

                      <div class="detail-section">
                        <div class="detail tag"> <button> <img src="assets/icons/tag.svg" alt="" width="20"> BOX AUTO</button> </div>
                        <div class="detail info"> <img src="assets/icons/plans.svg" alt="" width="35"> 40 m2</div>  
                        <div class="detail info"> <img src="assets/icons/stairs.svg" alt="" width="35"> 2° piano</div>  
                    </div>

                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis turpis id tellus accumsan 
                        tristique. Pellentesque porta nulla sagittis ultrices varius. Praesent id molestie turpis. Lorem ipsum 
                        dolor sit amet, consectetur adipiscing elit</p>
                    
                    <div class="tag-minor"> <button> <img src="assets/icons/tag.svg" alt="" width="17"> RISCALDAMENTO</button> </div>
                    <div class="tag-minor"> <button> <img src="assets/icons/tag.svg" alt="" width="17"> BAGNO</button> </div>
   
                    <div class="text-right price">
                          <p> da <span style="color: #12592D; font-size: 30px; font-weight: 500;" >230€ </span>/mese</p>
                          <button class="confirm-button"  >Contatta</button>
                        </div>

                        
                  </div>
              </div>

          </div>
      </div>



      <div class="row section_padding_50">
        <div class="col-md-12 card">
            <div class="row">
                <div class="col-md-5 card-img text-center">
                    <img src="assets/img/garage.jpg" alt="" width="380">
                </div>
                <div class="col-md-7 card-title">
                    <h3>Box auto interrato</h3>

                    <div class="detail-section">
                      <div class="detail tag"> <button> <img src="assets/icons/tag.svg" alt="" width="20"> BOX AUTO</button> </div>
                      <div class="detail info"> <img src="assets/icons/plans.svg" alt="" width="35"> 40 m2</div>  
                      <div class="detail info"> <img src="assets/icons/stairs.svg" alt="" width="35"> 2° piano</div>  
                  </div>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis turpis id tellus accumsan 
                      tristique. Pellentesque porta nulla sagittis ultrices varius. Praesent id molestie turpis. Lorem ipsum 
                      dolor sit amet, consectetur adipiscing elit</p>
                  
                  <div class="tag-minor"> <button> <img src="assets/icons/tag.svg" alt="" width="17"> RISCALDAMENTO</button> </div>
                  <div class="tag-minor"> <button> <img src="assets/icons/tag.svg" alt="" width="17"> BAGNO</button> </div>
 
                  <div class="text-right price">
                        <p> da <span style="color: #12592D; font-size: 30px; font-weight: 500;" >230€ </span>/mese</p>
                        <button class="confirm-button"  >Contatta</button>
                      </div>

                      
                </div>
            </div>

        </div>
    </div>

    </div>

 

  
